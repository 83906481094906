
import {Component} from 'vue-property-decorator';
import AbstractComponentVue from '../services/AbstractComponentVue';

@Component({
	components: {
	},
})
export default class NouveauCodeActivation extends AbstractComponentVue {
	private login: string = this.$route.query.login as string;
	private codeActivation: string = this.$route.query.codeActivation as string;

	private printSummary(): void {
    	window.print();
  	}
}
